<template >
    <div>
        <RemindersList />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name:"Reminders",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Teklifler', route: '/hatirlatmalar' },
        ]);
    },
    components:{
        RemindersList: () =>import('@/components/reminders/RemindersList.vue')
    }
}
</script>
<style lang="">
    
</style>